



















import { defineComponent, ref, watch } from '@vue/composition-api';
import { BottomOverlay, CircleSpinner } from '@nimiq/vue-components';
import { useSettingsStore } from '../stores/Settings';
import { updateServiceWorker } from '../registerServiceWorker';

export default defineComponent({
    setup() {
        const isShown = ref(false);

        const { updateAvailable } = useSettingsStore();
        watch(updateAvailable, (isAvailable) => {
            if (isAvailable) isShown.value = true;
        });

        const applyingWalletUpdate = ref(false);

        async function applyWalletUpdate() {
            await updateServiceWorker();
            applyingWalletUpdate.value = true;
        }

        return {
            isShown,
            applyWalletUpdate,
            applyingWalletUpdate,
        };
    },
    components: {
        BottomOverlay,
        CircleSpinner,
    },
});
