




import { defineComponent } from '@vue/composition-api';
import { ArrowRightSmallIcon } from '@nimiq/vue-components';

export default defineComponent({
    components: {
        ArrowRightSmallIcon,
    },
});
