


























import { defineComponent, ref, watch, computed, onMounted, Ref } from '@vue/composition-api';
import { LoadingSpinner } from '@nimiq/vue-components';
import Sidebar from './components/layouts/Sidebar.vue';
import SwapNotification from './components/swap/SwapNotification.vue';
import UpdateNotification from './components/UpdateNotification.vue';
import router, { provideRouter } from './router';
import { useAccountStore } from './stores/Account';
import { useSettingsStore } from './stores/Settings';
import { useWindowSize } from './composables/useWindowSize';
import { useActiveMobileColumn } from './composables/useActiveMobileColumn';
import { useSwipes } from './composables/useSwipes';

export default defineComponent({
    name: 'app',
    setup(props, context) {
        provideRouter(router);

        const { activeMobileColumn } = useActiveMobileColumn();

        const { accountInfos } = useAccountStore();
        // Convert result of computation to boolean, to not trigger rerender when number of accounts changes above 0.
        const hasAccounts = computed(() => Boolean(Object.keys(accountInfos.value).length));

        const { amountsHidden, swipingEnabled } = useSettingsStore();

        // Swiping
        const main$ = ref<HTMLDivElement>(null);
        let mobileTapArea$: HTMLDivElement | null = null;
        const { width, isMobile } = useWindowSize();

        async function updateSwipeRestPosition(
            velocityDistance: number,
            velocityTime: number,
            initialXPosition: number,
            currentXPosition: number,
        ) {
            if (velocityDistance && velocityTime) {
                const swipeFactor = 10;
                const velocity = (velocityDistance / velocityTime) * 1000 * swipeFactor; // px/s
                const remainingXDistance = Math.sqrt(Math.abs(velocity)) * (velocity / Math.abs(velocity));
                // console.log(`Travelled ${velocity}px/s, will travel ${remainingXDistance}px more`);
                currentXPosition += remainingXDistance;
            }

            const sidebarBarrier = -192 / 2;
            const transactionsBarrier = -(window.innerWidth / 2 + 192);

            if (currentXPosition >= sidebarBarrier && (initialXPosition) < sidebarBarrier) {
                // Go to sidebar
                await context.root.$router.push({ name: context.root.$route.name!, query: { sidebar: 'true' } });
            } else if (currentXPosition <= transactionsBarrier && (initialXPosition) > transactionsBarrier) {
                // Go to transactions
                if (context.root.$route.name === 'root') {
                    await context.root.$router.push('/transactions');
                }
            } else if (
                (currentXPosition <= sidebarBarrier && currentXPosition >= transactionsBarrier)
                && (initialXPosition > sidebarBarrier || initialXPosition < transactionsBarrier)
            ) {
                // Go back to root (addresses)
                context.root.$router.back();
                await context.root.$nextTick();
            }
        }

        function onSwipeFrame(position: number) {
            if (position <= -192) return;
            if (!mobileTapArea$) {
                mobileTapArea$ = document.querySelector('.mobile-tap-area') as HTMLDivElement;
            }
            mobileTapArea$.style.transition = 'initial';
            mobileTapArea$.style.opacity = `${1 - (position / -192)}`;
        }

        function resetStyles() {
            if (!mobileTapArea$) return;
            mobileTapArea$.style.transition = '';
            mobileTapArea$.style.opacity = '';
            mobileTapArea$ = null;
        }

        const { attachSwipe, detachSwipe } = useSwipes(main$ as Ref<HTMLDivElement>, {
            onSwipeEnded: updateSwipeRestPosition,
            // TODO: clamp movement to smaller area on settings and network view
            clampMovement: computed<[number, number]>(() => {
                if (context.root.$route.path === '/transactions') {
                    // Allow swiping back from transactions to address list, but not all the way to sidebar
                    return [-width.value - 192, -192];
                }
                // Otherwise only allow swiping between main column and sidebar
                return [-192, 0];
            }),
            onFrame: onSwipeFrame,
            reset: resetStyles,
            excludeSelector: '.scroller, .scroller *',
        });

        watch([isMobile, swipingEnabled], ([isMobileNow, newSwiping], [wasMobile, oldSwiping]) => {
            if (!main$.value) return;

            if ((isMobileNow && !wasMobile) || (newSwiping === 1 && oldSwiping !== 1)) {
                attachSwipe();
            } else if (!isMobileNow || newSwiping !== 1) {
                detachSwipe();
            }
        }, { lazy: true });

        onMounted(() => {
            if (isMobile.value && swipingEnabled.value === 1) attachSwipe();
        });

        return {
            activeMobileColumn,
            hasAccounts,
            amountsHidden,
            main$,
        };
    },
    components: {
        Sidebar,
        SwapNotification,
        UpdateNotification,
        LoadingSpinner,
    },
});
